import { Button, EButtonSizes } from 'liw-common-fe/@liw-components/Button';
import { DropDown, IRenderArguments } from 'liw-common-fe/@liw-components/DropDown';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'utils';
import { UserProfile } from './UserProfile';
import styles from './UserMenu.module.scss';

interface UserMenuProps {
    onOpen: () => void
    onLogout?: () => Promise<void>
    userData: any
  }
  
export const UserMenu: FC<UserMenuProps> = ({ onOpen, onLogout, userData }) => {
  const { t } = useTranslation();
  const header = useCallback(({ updateStatus, status }: IRenderArguments) => (
    <Button
      onClick={() => updateStatus(showContent => !showContent)}
      icon="bars"
      className={cn(styles.burger, {
        [styles.active]: status,
      })}
      iconClassname={styles.hamgurguerIcon}
      size={EButtonSizes.large}
    /> 
  ), []);
  const content = useCallback(({ updateStatus }: IRenderArguments) => (
    <div className={styles.content}>
      <UserProfile 
        userName={userData.userName} 
        userGroups={userData.group} 
      />
      <div className={styles.preferencesContent}>
        <Button 
          className={styles.preferencesButton} 
          text={'User Preferences'} 
          icon='dial'
          onClick={() => {
            onOpen();
            updateStatus(false);
          }}
          iconClassname={styles.preferencesIcon}
        />
      </div>
      <Button 
        className={styles.logoutButton} 
        text={t('logout')} 
        onClick={onLogout} 
        icon='arrow-up-left-from-circle'
        iconClassname={styles.logoutIcon} 
      />
    </div>
  ), []);
  
  return (
    <DropDown
      className={styles.container}
      renderHead={header} 
      renderContent={content} 
    />
  );
};
