import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styles from './LoginButton.module.scss'

const LoginButton = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const login = () => oktaAuth.signInWithRedirect();
  if (authState.isPending) {
    return (
      <button className={styles.btn} onClick={login}>
        Log In
      </button>
    );
  } else if (!authState.isAuthenticated) {
    return (
      <button className={styles.btn} onClick={login}>
        Log In
      </button>
    );
  }
  return <div></div>;
};

export default LoginButton;
