
export enum EPermissionType {
  SEARCH_FOR_PARTY = 'search_for_party',
  VIEW_ARTIST_RECORD = 'view_artist_record',
  ASSIGN_ISNI = 'assign_isni',
  EDIT_ISNI = 'edit_isni',
  SEARCH_ISNI = 'search_isni',
  REQUEST_NEW_ISNI = 'request_new_isni',
  REMOVE_ISNI = 'remove_isni',
  CREATE_PARTY = 'create_party',
  EDIT_PARTY = 'edit_party',
  VIEW_PARTY = 'view_party',
}

export type Permission = {
  name: EPermissionType;
};

export interface getPermissionsData {
  permissions: Permission[];
}

export enum ELanguage {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR',
  HI = 'HI',
  RU = 'RU',
  ZH = 'ZH',
  DE = 'DE',
  HE = 'HE',
}

export interface ISettings {
  language: ELanguage,
  dateFormat?: EDateTimeFormat,
  timeFormat?: EDateTimeFormat,
}

export enum EUserGroup {
  SUPER_USER = 'Super User',
  DATA_USER = 'Data User',
}

export enum EDateTimeFormat {
  MM_DD_YYYY_Slash = 'MM/DD/YYYY',
  DD_MM_YYYY_Slash = 'DD/MM/YYYY',
  H_MM_SS = 'h:mm:ss',
  HH_MM_A = 'hh:mm A',
  H_MM_SS_A = 'h:mm:ss A',
  H_MM = 'H:mm'
}

export type TUser = {
  isAuthenticated: true,
  userName: string,
  userToken: string,
  group: ReadonlyArray<EUserGroup>,
  tokenExpiration: number,
} | {
  isAuthenticated: false,
}
