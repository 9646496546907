import { EPartyType } from 'modules/common/types/partyTypes';
import moment from 'moment';
import { EDateTimeFormat } from '../common/consts';

export { default as cn } from 'classnames';
export { default as moment } from 'moment';

export { match } from './match';

export const getAppTitlePrefix = () => {
  let prefix = '';

  switch (process.env.REACT_APP_ENV_NAME) {
    case 'Development':
      prefix = 'DEV';
      break;
    case 'QA':
      prefix = 'QA';
      break;
    default:
      prefix = '';
  }
  let title = prefix ? `[${prefix}]` : '';
  if (process.env.NODE_ENV === 'development') {
    title = '[LOCAL]' + title;
  }
  return title;
};

export const convertPartyTypeStringToEnum = (partyType: string): EPartyType => {
  switch (partyType.toUpperCase()) {
    case EPartyType.GROUP:
      return EPartyType.GROUP;
    case EPartyType.PERSON:
      return EPartyType.PERSON;
    default:
      throw new Error('Wrong party type string, expect one of EPartyType');
  }
};

function eitherNum<D>(num: number, def: D): D | number {
  if (isNaN(num)) return def;
  return num;
}

export function toNumber<D>(s: string | null | number, def: D): number | D {
  if (s === null) return def;
  if (typeof s === 'string') return eitherNum(parseInt(s, 10), def);
  return eitherNum(s, def);
}

// Links creation functions
export const createViewPartyPageLink = (partyId?: number) => partyId ? `/party/${partyId}` : '/not-found';
export const createEditPartyPageLink = (partyId?: number) => partyId ? `${createViewPartyPageLink(partyId)}/edit` : '/not-found';
export const createPartyHistoryPageLink = (partyId?: number) => partyId ? `${createViewPartyPageLink(partyId)}/history` : '/not-found';

// TODO: move to better place
// TODO: ENUM FOR COMPETENCIES IDS!!!!
export const PERFORMING_ARTIST_COMPETENCY_ID = 2;

// date and time formatting
export const timestampToUnix = (timestamp: string | number) => moment.unix(+timestamp);
export const formatDateTime = (timestamp: string | number, dateTimeFormat: EDateTimeFormat | string) =>
  timestampToUnix(timestamp).format(dateTimeFormat);

export function indexBy<T extends Record<string, any>, K extends keyof T>(list: T[], field: K): Record<string, T> {
  return list.reduce((a, v) => ({ ...a, [v[field]]: v }), {});
}

export function reverseName(name: string): string {
  return name.split(' ').reverse().join(' ').replace(',', '');
}