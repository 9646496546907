import React, { FC, useCallback, useMemo, useState } from 'react';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';

export interface IRenderArguments {
  status: boolean;
  updateStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IProps {
  renderHead(props: IRenderArguments): JSX.Element;
  renderContent(props: IRenderArguments): JSX.Element;
  className?: string;
}

export const DropDown: FC<IProps> = ({ renderHead, renderContent, className }) => {

  const [ isOpen, setStatus ] = useState<boolean>(false);

  const handleClose = useCallback<() => void>(() => setStatus(false), [setStatus]);

  const renderArguments = useMemo<IRenderArguments>(
    () => ({ status: isOpen, updateStatus: setStatus }),
    [isOpen]
  );

  const header = useMemo<JSX.Element>(() => renderHead(renderArguments), [renderArguments, renderHead]);
  const content = useMemo<JSX.Element>(() => renderContent(renderArguments), [renderArguments, renderContent]);

  const ref = useDetectClickOutside(handleClose);

  return (
    <div className={className} ref={ref}>
      {header}
      {
        isOpen ? content : null
      }
    </div>
  );
};
