import React, { FC, useState } from 'react';
import { cn } from 'utils';
import styles from './header.module.scss';
import { Logo } from './Logo/Logo';
import { useUser } from 'domains/env';
import { UserMenu } from './UserMenu/UserMenu';
import { useOktaAuth } from '@okta/okta-react';
import { UserModal } from './UserMenu/UserModal';

interface Props {
  loggedIn?: boolean;
}

const issuer = process.env.REACT_APP_OKTA_DOMAIN;
const redirectUri = `${window.location.origin}/`;
const authServerPath = process.env.REACT_APP_AUTH_SERVER_PATH;


const Header: FC<Props> = ({ loggedIn }) => {

  const { oktaAuth, authState } = useOktaAuth();
  const user = useUser();
  const [isMenuOpen, setMenuStatus] = useState<boolean>(false);
  const handleLogout = async () => {
    try {
      const idToken = authState.idToken ? authState.idToken.idToken : '';
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + '/',
      });
      // clear remote session
      window.location.href = `${issuer}${authServerPath}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <nav
      className={cn("header navbar-default header-nav", styles.navbar)}
      role="navigation"
    >
      <Logo />
      {loggedIn && (
        <UserMenu onOpen={() => setMenuStatus(true)} onLogout={handleLogout} userData={user} />        
      )}
      {
        isMenuOpen && <UserModal userData={user} onClose={() => setMenuStatus(false)} />
      }
    </nav>
  );
};

export default Header;
