import { EDateTimeFormat, ELanguage } from 'domains/env';

export const languageList = [
  { value: ELanguage.EN, label: 'English' },
  { value: ELanguage.ES, label: 'Español' },
  { value: ELanguage.FR, label: 'Français' },
  { value: ELanguage.ZH, label: '简体中文' },
  // { value: ELanguage.FR, label: 'עברית' }, // GP-2716
  { value: ELanguage.RU, label: 'Русский' },
  { value: ELanguage.HI, label: 'हिंदी' },
  { value: ELanguage.DE, label: 'Deutsch' },
];
  
export const dateTimeList = [
  {
    label: EDateTimeFormat.MM_DD_YYYY_Slash,
    value: 'mm/dd/yyyy', 
  },
  {
    label: EDateTimeFormat.DD_MM_YYYY_Slash, 
    value: 'dd/mm/yyyy',
  },
];

export const timeList = [
  {
    label: 'hh:mm a', 
    value: EDateTimeFormat.HH_MM_A,
  }, 
  {
    label: 'H:MM',
    value: EDateTimeFormat.H_MM,
  },
];