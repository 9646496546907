type Narrowable =
  | string
  | number
  | boolean
  | symbol
  | void
  | null
  | undefined
  | unknown;
const tuple = <T extends Narrowable[]>(...args: T) => args;

export default tuple;
