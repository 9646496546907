import LandingPage from 'modules/pages/LandingPage';
import { IRoutes } from './types';

export type TRoutes<K extends string> = Record<K, IRoutes<K>>;

const routes = {
  home: {
    id: 'home',
    component: () => LandingPage,
    restriction: true,
    props: {
      exact: true,
      path: '/home',
    },
  },
};

export type TRouterDict = TRoutes<keyof typeof routes>;

export const routesList = Object
  .values(routes)
  .map((r) => r) as ReadonlyArray<IRoutes<keyof typeof routes>>;

export const routesDictionary = routes as TRouterDict;

