import React, { useCallback } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import OktaAuthBrowser from '@okta/okta-auth-js/lib/browser/browser';
import flatMap from 'lodash/fp/flatMap';
import config from 'config/oktaConfig';
import AuthenticateUser from './AuthenticateUser';
import { routesList } from './Router'

// Polyfill to add compatibility w/ IE 11 & Edge
import '@okta/okta-auth-js/polyfill';

import './modules/common/styles/main.scss';
import LoginPage from './modules/pages/LoginPage';

export const oktaAuth = new OktaAuth({
  ...config,
  scopes: ['groups', 'openid', 'profile', 'email'],
});

const CALLBACK_PATH = process.env.REACT_APP_OKTA_CALLBACK_PATH;

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    async (oktaAuth: OktaAuthBrowser, originalUri: string) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    },
    [history]
  );

  return (
    <div className="app-wrapper">
      <Switch>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route exact path="/" component={LoginPage} />
          <Route path={CALLBACK_PATH} component={LoginCallback} />
          <SecureRoute path={flatMap('props.path')(routesList)} component={AuthenticateUser} />
        </Security>
      </Switch>
    </div>
  );
};

export default App;
