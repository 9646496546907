import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from './config/apiConfig';
import { routesList } from './Router';

const AuthenticateUser = () => {

  const { client } = useApolloClient();

  if (!client) return null;

  return (
    <ApolloProvider client={client}>
      {
        routesList.map(r => (
          <SecureRoute key={r.id} {...r.props} component={r.component()} />
        ))
      }
    </ApolloProvider>
  );
};

export default AuthenticateUser;
