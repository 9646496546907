import { useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { formatGroupNames, parseToken } from 'modules/common/helpers/userFuncs';
import { TUser } from '../types';

export const useUser = () => {
  const { authState } = useOktaAuth();

  const user = useMemo<TUser>(() => {
    if (authState.isAuthenticated && typeof authState.idToken?.value === 'string') {
      const { name, groups, exp } = parseToken(authState.idToken?.value);
      return {
        isAuthenticated: true,
        userName: name,
        userToken: authState.idToken?.value,
        group: formatGroupNames(groups),
        tokenExpiration: exp,
      }
    } return {
      isAuthenticated: false,
    }
  }, [authState]);

  return {
    ...user,
  };
}
