import React, { FC, useMemo } from 'react';
import { cn } from 'utils';
import { WorkspacePreview } from 'liw-common-fe/@liw-components/WorkspacePreview/WorkspacePreview';
import { lbmLogo } from '../logos';
import { EDateTimeFormat, useSettings } from 'domains/env';
import {useTranslation} from "react-i18next";
import styles from './BuzzMill.module.scss';

type TBuzzMillProps = {
  className?: string
}

export const BuzzMill: FC<TBuzzMillProps> = (props) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const date = useMemo(() => settings?.dateFormat === EDateTimeFormat.MM_DD_YYYY_Slash.toLowerCase() ? '09/13/2021' : '13/09/2021', [settings?.dateFormat])
  return (
    <div className={cn(styles.container, props.className)}>
      <div className={styles.header}>
        <WorkspacePreview
          title="LIW"
          subtitle={t('liw')}
          icon={<img src={lbmLogo} alt="pw-logo" />}
        />
      </div>
      <div className={styles.content}>
        LIW is an integrated suite of Workspaces used to create and manage data for Parties, Works, Assets and Products. More workspaces will continue to be added as they go online. All records in LIW will provide data for downstream use in WMG applications. Keep an eye on this space for future updates and app news!
      </div>
      <div className={styles.footer}>
        <span className={styles.author}>LIW Admin</span>
        <span className={styles.date}>{date}</span>
      </div>
    </div>
  );
};
