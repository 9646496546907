import { oktaAuth } from 'App';

export const isTheSameUser = async (email: string) => {
  try {
    const existSession = await oktaAuth.session.exists();
    const sessionInfo = await oktaAuth.session.get();
    const user = await sessionInfo.user?.();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sessionEmail = user?.profile.email;
    if (!existSession && sessionEmail) {
      localStorage.clear();
    }
    if (sessionEmail && (email !== sessionEmail)) {
      localStorage.clear();
      await oktaAuth.signInWithRedirect();
    }
  } catch (error) {
    console.warn(error);
  }
}