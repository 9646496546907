import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import { Subheader } from 'liw-common-fe/@liw-components/Subheader/Subheader';
import Layout from '../../common/components/Layout';
import { BuzzMill } from './BuzzMill';
import { Workspaces } from './Workspaces';
import { Widget } from './Widget';
import styles from './landing-page.module.scss';
import { useOktaAuth } from '@okta/okta-react';
import { isTheSameUser } from 'utils/validateCurrentUser';

const LandingPage = () => {
  const { t } = useTranslation();
  const { oktaAuth, authState } = useOktaAuth();
  useEffect(() => {
    isTheSameUser(authState.idToken?.claims.email as string);
  },[oktaAuth])
  return (
    <>
      <Layout loggedIn>
        <div className={styles.wrapper}>
          <Subheader
            location="LIW"
            mainInfo={t('liw')}
            sticky
          />
          <div className={styles.container}>
            <Widget className={styles.itemC} title={'Buzz Mill'}><BuzzMill /></Widget>
            <Widget className={styles.itemC} title={'Workspaces'}><Workspaces /></Widget>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LandingPage;
