import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import Layout from '../../common/components/Layout';
import LoginBtn from './components/LoginBtn';
import contactIcon from './images/ contactIcon.svg'
import styles from './LoginPage.module.scss'

const LoginPage = () => {
  const { authState } = useOktaAuth();
  if (authState.isAuthenticated && !authState.isPending) {
    return <Redirect to="/home" />;
  } else {
    return (
      <Layout>
        <div className="login-container">
          <div className="container main align-center">
            <div className="row centered-row">
              <main className="well well-no-border col-md-9">
                <div className={styles.cardBody}>
                  <div className={styles.cardBody_title}>
                    <div className={styles.logo}/>
                    <h1 className={styles.title}>
                      Label Copy Integrated Workspaces
                    </h1>
                  </div>
                  <div className={styles.cardBody_about}>
                    <h4 className={styles.about}>
                      An integrated suite of workspaces used to create and manage data for Parties, Works, Assets, and
                      Products.
                    </h4>
                    <LoginBtn />
                    <div className={styles.contact}>
                      <h4 className={styles.contact_description}>
                        <a
                          href="https://warnermusic.service-now.com/wmg/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Having issues logging in?
                        </a>
                        </h4>
                      <img className={styles.contact_icon} src={contactIcon} alt="contactIcon"/>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
};

export default LoginPage;
