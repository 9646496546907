import React, { FC, useEffect } from 'react';
import { cn } from 'utils';
import { focusing } from 'utils/focus';
import { IPopupProps } from './types';
import { useTabNavigation } from './useTabNavigation';
import styles from './popup.module.scss';

export const Popup: FC<IPopupProps> = ({
  name,
  renderCancelBtn,
  containerClassName,
  className,
  children,
}) => {

  const { handleRef } = useTabNavigation<HTMLDialogElement>();

  useEffect(
    () => {
      const lastFocused = focusing();
      document.body.classList.add(styles.overflow, 'user-is-tabbing');
      return () => {
        document.body.classList.remove(styles.overflow, 'user-is-tabbing');
        lastFocused.back();
      };
    },
    []
  );

  return (
    <dialog
      className={cn('user-is-tabbing', styles.container, containerClassName)}
      aria-hidden="false"
      aria-labelledby={name}
      ref={handleRef}
    >
      <div
        className={cn(styles.popup, className)}
      >
        {
          children
        }
        {
          typeof renderCancelBtn !== 'undefined' ? renderCancelBtn : null
        }
      </div>
    </dialog>
  );
};
