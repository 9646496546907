import React, { FC } from 'react';
import { UserImage } from '../UserImage';
import styles from './UserProfile.module.scss';

interface UserProfileProps {
    userName: string
    userGroups: ReadonlyArray<string>
    userUrlImage?: string
}

export const UserProfile: FC<UserProfileProps> = ({ userName, userGroups, userUrlImage }) => {
  return (
    <div className={styles.userContent}>
      <UserImage urlImage={userUrlImage} />
      <div className={styles.descriptionContainer}>
        <p className={styles.name}>{userName}</p>
        {userGroups.map(item => (<p key={item}>{item}</p>))}
      </div>
    </div>
  );
};
