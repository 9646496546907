import React, { FC } from 'react';
import styles from './WorkspacePreview.module.scss';
import { cn } from 'utils';

type TWorkspacePreviewProps = {
  title?: string
  subtitle?: string
  icon?: JSX.Element | string
  description?: string
  className?: string
}

export const WorkspacePreview: FC<TWorkspacePreviewProps> = (props) => {
  const { icon, title, subtitle, description } = props;
  return (
    <div className={cn(styles.container, props.className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        {description && <div className={styles.description}>
          {description}
        </div>}
      </div>
    </div>
  );
};
