import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
interface Props {
  loggedIn?: boolean;
}



const Layout: React.FC<Props> = ({ loggedIn, children }) => {
    return (
    <>
      <Header
        loggedIn={loggedIn}
      />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
