import React, { useCallback, useMemo } from 'react';
import styles from './elements.module.scss';

interface IElements {
  onClose(): void;
  onCancel?(): void;
  loading?: boolean;
}

interface IButton {
  className?: string,
  children?: string | JSX.Element,
  onClick: () => void;
  ['aria-label']?: string;
  disabled?: boolean;
  key: string;
  'data-tabindex'?: number;
}

export const useElements = ({ onClose, loading, onCancel }: IElements) => {

  const btn = useCallback<(btnProps: IButton) => JSX.Element>(
    (props) => (
      <button type="button" {...props} />
    ),
  []
  );

  const actionButton = useCallback(
    (key: string, actionText?: string, onAction?: () => void) => (actionText && onAction) ? btn({
      onClick: onAction,
      children: actionText,
      className: styles['action-btn'],
      disabled: loading,
      key,
      'data-tabindex': 1,
    }) : null,
    [loading]
  );

  const cancelButton = useCallback(
    (key: string, cancelText?: string) => cancelText ? btn({
      onClick: onCancel || onClose,
      children: cancelText,
      className: styles['cancel-btn'],
      disabled: loading,
      key,
      'data-tabindex': 0,
    }) : null,
    [onClose, loading, onCancel]
  );

  const closeButton = useMemo(
    () => btn({
      onClick: onClose,
      className: styles['close-btn'],
      ['aria-label']: 'Close dialog',
      key: 'close',
      'data-tabindex': 5,
    }),
    [onClose]
  );

  return {
    actionButton,
    cancelButton,
    closeButton,
  };
};
