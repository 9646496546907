import { useCallback, useMemo } from 'react';
import { ApolloClient, ApolloLink, from, InMemoryCache, split, HttpLink, Operation } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { appsyncConfig } from './appsyncConfig';
import { useUser } from 'domains/env';

const { aws_appsync_region, aws_appsync_graphqlEndpoint } = appsyncConfig;

export const useApolloClient = () => {

  const user = useUser();

  const authLink = useCallback<(token: string, url: string, region: string) => ApolloLink>(
    (jwtToken, url, region) => createAuthLink({
      url,
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken,
      },
      region,
    }),
    []
  );

  const linkSplit = useCallback<(o: Operation) => boolean>(
    ({ query }) => {
      const definition = query.definitions[0];
      return !(definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
    },
    []
  );

  const httpLinkSearch = useCallback(
    (uri) => new HttpLink({ uri }),
    []
  )

  const client = useMemo(
    () => {
      if (
        user.isAuthenticated
        && typeof aws_appsync_graphqlEndpoint === 'string'
        && typeof  aws_appsync_region === 'string'
      ) {
        return new ApolloClient({
          cache: new InMemoryCache(),
          link: from([
            authLink(user.userToken, aws_appsync_region, aws_appsync_graphqlEndpoint),
            split(linkSplit, httpLinkSearch(aws_appsync_graphqlEndpoint)),
          ]),
          connectToDevTools: process.env.NODE_ENV !== 'production',
        });
      }
    },
    [authLink, httpLinkSearch, linkSplit, user]
  );

  return { client };
};
