import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button } from 'liw-common-fe/@liw-components/Button';
import styles from './UserForm.module.scss';
import { Select } from 'liw-common-fe/@liw-components/Form/Select/Select';
import { EDateTimeFormat, ELanguage, useSettings } from 'domains/env';
import { dateTimeList, languageList, timeList } from './optionsList';
import { setLang } from 'utils/i18n';
interface UserFormProps {
  onCancel?: () => void
}


export const UserForm: FC<UserFormProps> = ({ onCancel }) => {
  const { settings, updateSettings } = useSettings();
  const settingsValues = {
    dateFormat: settings?.dateFormat,
    language: settings?.language,
    timeFormat: settings?.timeFormat,
  };
  const [ formValues, setFormValues ] = useState(settingsValues);
  const handleInputChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    })
  }
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onCancel && onCancel();
    await updateSettings(formValues.language as ELanguage, formValues.timeFormat as EDateTimeFormat, formValues.dateFormat as EDateTimeFormat);
    setLang(formValues.language);
  };

  useEffect(() => {
    setFormValues({
      dateFormat: settings?.dateFormat,
      language: settings?.language,
      timeFormat: settings?.timeFormat,
    })
  }, [settings]) 


  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.selectContent}>
        <Select
          label={'Language'}
          name='language'
          options={languageList}
          onChange={handleInputChange}
          value={formValues.language}
          />  
        <Select
          label={'Date Format'} 
          name='dateFormat'
          options={dateTimeList}
          onChange={handleInputChange}
          value={formValues.dateFormat}
          />  
        <Select
          label={'Time Format'} 
          name='timeFormat'
          options={timeList}
          onChange={handleInputChange}
          value={formValues.timeFormat}
          />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttonCancel}
          text={'Cancel'}
          type='button'
          onClick={onCancel}
        />  
        <Button
          className={styles.buttonSave}
          text={'Save And Close'}
          type='submit'
        />  
      </div>
    </form>
  );
};
