import React, { FC } from 'react';
import { Tooltip } from 'liw-common-fe/@liw-components/Tooltip/Tooltip';
import { Icon } from 'liw-common-fe/@liw-components/Icon/Icon';
import commonStyles from 'liw-common-fe/@liw-components/Form/CommonStyles.module.scss';
import { cn } from 'utils';

type TIconWithToolTipProps = {
  text: string | JSX.Element
  icon: string
  iconPrefix?: string
  iconClassname?: string
  className?: string
}
export const IconWithToolTip: FC<TIconWithToolTipProps> = (props) => {
  const { text, icon, iconPrefix } = props;
  return (
    <Tooltip text={text} className={props.className}>
      <Icon icon={icon} iconPrefix={iconPrefix} className={props.iconClassname} />
    </Tooltip>
  );
};

type TWarningIconProps = {
  text: string | JSX.Element
  className?: string
}
export const WarningIcon: FC<TWarningIconProps> = (props) => (
  <IconWithToolTip
    icon="triangle-exclamation"
    className={cn(commonStyles.warningIcon, props.className)}
    text={props.text}
  />
);
