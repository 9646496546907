import React, { FC } from 'react';
import { cn } from 'utils';
import styles from './Icon.module.scss';

type TIconProps = {
  icon: string
  iconPrefix?: string
  className?: string
  tooltipId?: number
}

export const Icon: FC<TIconProps> = (props) => {
  const tooltipAttrs = props.tooltipId !== undefined ? {
    'data-tip': true,
    'data-for': 'tooltip-' + props.tooltipId,
  } : null;

  return (
    <i className={cn(styles.icon, props.className)}>
      <svg {...tooltipAttrs}>
        <use xlinkHref={`/icons/icons.svg#${props.icon}`} />
      </svg>
    </i>
  );
};
