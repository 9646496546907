import React, { FC } from 'react';
import cn from 'classnames';
import { ESpinnerSize } from 'liw-common-fe/@liw-components/Spinners/consts';
import { BasicSpinner } from 'liw-common-fe/@liw-components/Spinners/BasicSpinner';
import { Icon } from 'liw-common-fe/@liw-components/Icon/Icon';
import styles from './Button.module.scss';

export enum EButtonVariants {
  default = 'default',
  primary = 'primary',
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  link = 'link',
}

export enum EButtonSizes {
  mini = 'xs',
  small = 'sm',
  large = 'lg',
}

export type TButtonProps = {
  text?: string | JSX.Element
  variant?: EButtonVariants
  size?: EButtonSizes
  className?: string
  icon?: string
  iconLeft?: boolean
  iconClassname?: string
  showSpinner?: boolean
} & JSX.IntrinsicElements['button']

export const Button: FC<TButtonProps> = (props) => {
  const { type = 'button', variant = EButtonVariants.default, size, icon, text, disabled, iconLeft, iconClassname, showSpinner, ...intrinsicProps } = props;

  return (
    <button
      disabled={disabled}
      {...intrinsicProps}
      className={cn(styles.button,'btn', `btn-${variant}`, {
        [`btn-${size}`]: !!size,
        'disabled': disabled,
        [styles.iconOnly]: icon && !text && !props.children,
        [styles.iconLeft]: iconLeft,
      }, props.className)}
      type={type}>
      {text ? <span>{text}</span> : props.children}
      {!!icon && <Icon icon={icon} className={cn(styles.icon, iconClassname)} />}
      {showSpinner && <div className={styles.spinnerWrapper}>
        <BasicSpinner size={ESpinnerSize.SMALL} />
      </div>}
    </button>
  );
};

