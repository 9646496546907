import React, { FC } from 'react';
import { cn } from 'utils';
import { FieldRenderProps } from 'react-final-form';
import { TSelectOption } from 'common/types';
import { WarningIcon } from 'liw-common-fe/@liw-components/Icon/IconWithTooltip';
import { ESpinnerSize } from 'liw-common-fe/@liw-components/Spinners/consts';
import { BasicSpinner } from 'liw-common-fe/@liw-components/Spinners/BasicSpinner';
import { Tooltip } from 'liw-common-fe/@liw-components/Tooltip/Tooltip';
import { Icon } from 'liw-common-fe/@liw-components/Icon/Icon';

import styles from './Select.module.scss';
import commonStyles from '../CommonStyles.module.scss';

type TSelectProps = JSX.IntrinsicElements['select'] & {
  label?: string
  labelTooltip?: string
  disabledTooltip?: string
  placeholder?: string
  className?: string
  options?: TSelectOption[]
  error?: string
  name: string
  loading?: boolean
}

export const Select: FC<TSelectProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    options = [],
    className,
    name,
    error,
    loading,
    labelTooltip,
    disabledTooltip,
    ...intrinsicProps
  } = props;

  const renderControl = () => (
    <select
      name={name}
      className={cn('form-control', styles.select, {
        [styles.placeholder]: !intrinsicProps.value,
      })}
      {...intrinsicProps}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option) =>
        <option key={`selectOption_${option.value}`} value={option.value} disabled={option?.isDisabled}>
          {option.label}
        </option>
      )}
    </select>
  );

  return (
    <div className={cn('form-group', {
      'required': required,
      'has-error': !!error,
    }, className)}>
      {label && (
        <label className={cn('control-label', commonStyles.inputLabel)}>
          {labelTooltip ? (
            <Tooltip text={labelTooltip}>{label}</Tooltip>
          ) : (
            <span>{label}</span>
          )}
          {required && <Icon icon="asterisk" className={commonStyles.iconInLabel} />}
          {loading && <BasicSpinner size={ESpinnerSize.SMALL} className={commonStyles.labelSpinner} />}
          {!loading && error && (<WarningIcon text={error} className={commonStyles.iconInLabel} />)}
        </label>
      )}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}

    </div>
  );
};

export const FFSelect: FC<FieldRenderProps<string> & TSelectProps & { forceError?: boolean }> = (props) => {
  const { input, meta, forceError, loading, ...rest } = props;
  return (
    <Select
      {...input}
      loading={meta.validating || loading}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};
