import { Button, EButtonSizes } from 'liw-common-fe/@liw-components/Button';
import { Icon } from 'liw-common-fe/@liw-components/Icon/Icon';
import React, { FC } from 'react';
import { cn } from 'utils';
import styles from './UserImage.module.scss';

export enum EUserImageSizes {
  small = 'sm',
  large = 'lg'
}
interface UserImageProps {
  urlImage?: string
  size?: EUserImageSizes
  editable?: boolean
  action?: () => void
}

export const UserImage: FC<UserImageProps> = ({ urlImage, size = 'sm', editable = false, action }) => {
  return (
    <div className={styles.imageContent}>
      <div 
        className={cn(styles.imageContainer, {
          [styles.small]: size === EUserImageSizes.small,
          [styles.large]: size === EUserImageSizes.large,
        })}
      >
        {
          urlImage ? <img src={urlImage} alt='user image' /> :
            <Icon icon='user' />
        }
      </div>
      {
        editable && 
        <Button 
          text={urlImage ? 'Replace Photo' : 'Add Photo'}
          size={EButtonSizes.small}
          icon='plus'
          onClick={action}
          className={styles.imageButton}
        />
      }
    </div>
  );
};
