import React, { FC, Fragment } from 'react';
import styles from './Tooltip.module.scss';
import ReactTooltip, { Place, Type } from 'react-tooltip';
import { cn } from 'utils';

type TTooltipProps = {
  text: string | JSX.Element,
  place?: Place
  block?: boolean
  className?: string,
  tooltipClassName?: string,
  clickable?: boolean,
  event?: string,
  eventOff?: string,
  type?: Type
}
let tooltipId = 0;
export const Tooltip: FC<TTooltipProps> = (props) => {
  return (
    <Fragment>
      <span
        className={cn(styles.content, props.block && styles.block, props.className)}
        data-tip={true}
        data-for={`t_${tooltipId}`}
      >{props.children}</span>
      <ReactTooltip
        id={`t_${tooltipId++}`}
        effect="solid"
        place={props.place}
        delayShow={300}
        className={cn(styles.tooltip, props.tooltipClassName)}
        clickable={props.clickable}
        event={props.event}
        eventOff={props.eventOff}
        type={props.type}
      >
        {props.text}
      </ReactTooltip>
    </Fragment>
  );
};
