import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'assets/translations/en.json';

const DEFAULT_LANG = 'en';

export const translateDocumentTitle = (lang: string): void => {
  const title = i18n.getResource(lang, 'common', 'workspace');
  document.title = `LIW / ${title}`;
};

export const setLang = (l: string | undefined): void => {
  if (typeof l === 'string') {
    const lang = l.toLowerCase();
    if (lang !== i18n.language) {
      import(`assets/translations/${lang}.json`)
        .then((result) => {
          const { default: namespaces } = result;
          Object.keys(namespaces).forEach((ns =>
              i18n.addResources(lang, ns, namespaces[ns])
          ));
          i18n.changeLanguage(lang);
          document.documentElement.lang = lang;
        })
        .catch(err => {
          console.warn(err);
        });
    }
  }
};

export const initI18n = (): void => {
  i18n.use(initReactI18next)
    .init({
      resources: {
        [DEFAULT_LANG]: {
          common: en.common,
          errors: en.errors,
          warnings: en.warnings,
          tooltips: en.tooltips,
        },
      },
      lng: DEFAULT_LANG,
      fallbackLng: DEFAULT_LANG,
      ns: ['common', 'tooltips', 'errors', 'warnings'],
      defaultNS: 'common',
      react: {
        transSupportBasicHtmlNodes: true,
      },
    });

  // i18n.on('languageChanged', translateDocumentTitle);

};
