import React, { FC } from 'react';
import { ModalPortal, Popup } from 'liw-common-fe/@liw-components/Popup';
import { useElements } from 'liw-common-fe/@liw-components/Popup/elements/useElements';
import styles from './UserModal.module.scss';
import { Icon } from 'liw-common-fe/@liw-components/Icon/Icon';
import { EUserImageSizes, UserImage } from '../UserImage/';
import { useTranslation } from 'react-i18next';
import { UserForm } from '../UserForm';

interface UserModalProps {
  onClose: () => void
  userData: any
}

export const UserModal: FC<UserModalProps> = ({ onClose, userData }) => {
  const { t } = useTranslation();
  const userName = userData.userName;
  const groups: string[] = userData.group;
  const { closeButton: CloseBtn } = useElements({
    onClose,
  });



  return (
    <ModalPortal>
      <Popup name='user_preferences_modal' className={styles.userModal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle}>
            <Icon icon='dial'/>
            <h3>{t('User Preferences')}</h3>
          </div>
          <div className={styles.modalClose}>
            {
              CloseBtn
            }
          </div>
        </div>
        <div className={styles.modalContent}>
          <UserImage 
            size={EUserImageSizes.large} 
            // action={() => console.log('hello')}
          />
          <div className={styles.descriptionContent}>
            <span>{t('Name')}</span>
            <p>
              {
                userName ? userName : 'Unknown'
              }
            </p>
            <span>{t('role')}</span>
            {
              groups.length > 0 && groups.map(item => 
                <p key={item}>
                  {item}
                </p>)
            }
          </div>
          <UserForm onCancel={onClose} />
        </div>
      </Popup>
    </ModalPortal>
  );
};
