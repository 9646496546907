import { useEffect } from 'react';

export enum EEventType {
  storage = 'storage',
  onClose = 'onclose',
  scroll = 'scroll'
}

export const useEventListener = (type: string, listener: any) => {
  useEffect(() => {
    window.addEventListener(type, listener);

    return () => {
      window.removeEventListener(type, listener);
    };
  }, []);
};