import {
  ENamePrivacyType,
  EPartyType,
  Identifier, PartyCompetency, Remark,
  TPartyLock,
  Translation
} from 'modules/common/types/partyTypes';

export enum EPartySubType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  CHARACTER = 'CHARACTER',
  BAND = 'BAND',
  ORCHESTRA = 'ORCHESTRA',
}

export enum ENameType {
  PKA = 'PKA',
  AKA = 'AKA',
  FKA = 'FKA',
}

export type TPartyName = {
  nameValue: string;
  nameType: ENameType;
  privacyType?: ENamePrivacyType;
  nameId?: number;
  isLegal?: boolean;
  majorGenreId?: number | '';
  minorGenreId?: number | '';
  translations?: Translation[];
  competencies?: PartyCompetency[];
  remarks?: Remark[];
  identifiers?: Identifier[];
  partyId?: number;
  nameCreatedBy: string;
  nameCreatedAt: string;
  nameUpdatedAt: string;
  nameUpdatedBy: string;
};

export type TPartyEntity = {
  partyId: number;
  partyType: EPartyType;
  partySubType: EPartySubType;
  dateOfBeginningYear?: number;
  dateOfBeginningMonth?: number;
  dateOfBeginningDay?: number;
  dateOfEndYear?: number;
  dateOfEndMonth?: number;
  dateOfEndDay?: number;
  countryOfOriginId?: number;
  languageOfOriginId?: number;
  createdById: string;
  lock?: TPartyLock;
}

export type TPartyEntryDetail = { party: TPartyEntity, names: TPartyName[] };

