import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspacePreview } from 'liw-common-fe/@liw-components/WorkspacePreview/WorkspacePreview';
import { cn } from 'utils';
import { pwLogo, prwLogo, awLogo, wwLogo } from '../logos'
import styles from './workspaces.module.scss';

type TWorkspacesList = {
  title: string,
  subtitle: string,
  content: string,
  status: string,
  logo: string,
  to: string,
}

const LIST: TWorkspacesList[] = [
  {
    title: 'LIW',
    subtitle: 'Party Workspace',
    content: 'Contributor metadata and Info.',
    status: 'online',
    logo: pwLogo,
    to: process.env.REACT_APP_PARTY_WORKSPACE_URL || '#',
  },
  {
    title: 'LIW',
    subtitle: 'Work Workspace',
    content: 'Publishing and licensing.',
    status: 'under_construction',
    logo: wwLogo,
    to: '#',
  },
  {
    title: 'LIW',
    subtitle: 'Asset Workspace',
    content: 'Media and WMG IP.',
    status: 'under_construction',
    logo: awLogo,
    to: '#',
  },
  {
    title: 'LIW',
    subtitle: 'Product Workspace',
    content: 'Production and sales.',
    status: 'under_construction',
    logo: prwLogo,
    to: '#',
  },
]

export const Workspaces: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {
          LIST.map(e => (
            <li key={e.subtitle} className={styles.item}>
              <a href={e.to} className={styles.link}>
                <div className={styles.wrapper}>
                  <WorkspacePreview
                    title={e.title}
                    subtitle={e.subtitle}
                    icon={<img src={e.logo} alt={`${e.subtitle} logo`} />}
                    />
                  <p className={styles.content}>{e.content}</p>
                </div>
                <div className={cn(styles.status, styles[e.status])}>{t(e.status)}</div>
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  );
}
