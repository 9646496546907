import React, { useMemo } from 'react';
import { SPINNER_COLOR, SPINNER_SPECS_LARGE, SPINNER_SPECS_SMALL, SPINNER_SPECS_MEDIUM, ESpinnerSize } from './consts';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { cn } from 'utils';

interface Props {
  size?: ESpinnerSize;
  className?: string;
}


export const BasicSpinner: React.FC<Props> = ({ size, className }) => {
  const spinnerSpecs = useMemo(() => {
    switch (size) {
    case ESpinnerSize.SMALL: return SPINNER_SPECS_SMALL;
    case ESpinnerSize.MEDIUM: return SPINNER_SPECS_MEDIUM;
    case ESpinnerSize.LARGE: default: return SPINNER_SPECS_LARGE;
    }
  }, [size]);

  return (
    <div className={cn('spinner-wrapper', className)}>
      <ScaleLoader
        color={SPINNER_COLOR}
        height={spinnerSpecs.height}
        width={spinnerSpecs.width}
        radius={spinnerSpecs.radius}
        margin={spinnerSpecs.margin}
        loading={true}
      />
    </div>
  );
};
